import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy,
  useCallback,
} from 'react';
import RootState from '~types/rootState';
import { Instrument, Order, Subscription } from '~types/common';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from '~components/common/Notifications';
import Loading from '~components/common/Loading';
import logger from '~utils/logger';
import returnStatusUtil from '~utils/status';
import getInitialLayout from '~utils/getInitialLayout';
import { orders as orderLang } from '~configs/language';
import {
  userPermissions as userPermissionsAction,
  reduxSetMatcherSystemStatus as reduxSetMatcherSystemStatusAction,
  reduxSetSessionStatus as reduxSetSessionStatusAction,
  reduxSetUserSettings as reduxSetUserSettingsAction,
  reduxSetPermissions as reduxSetPermissionsAction,
  reduxFollowClients as reduxFollowClientsAction,
  reduxSetSessionServiceStartup as reduxSetSessionServiceStartupAction,
  reduxBookUpdate as reduxBookUpdateAction,
  reduxUpdateAssetRfq as reduxUpdateAssetRfqAction,
  reduxRfqUpdate as reduxRfqUpdateAction,
  reduxSetHistoricalOrders as reduxSetHistoricalOrdersAction,
  reduxFollowOrder as reduxFollowOrderAction,
  reduxInstrumentsCreditCheck as reduxInstrumentsCreditCheckAction,
  reduxDisableUI as reduxDisableUIAction,
  reduxPostAsset as reduxPostAssetAction,
  reduxIncomingOrder as reduxIncomingOrderAction,
  reduxStpUpdate as reduxStpUpdateAction,
  reduxUpdateAsset as reduxUpdateAssetAction,
  reduxCompletedOrder as reduxCompletedOrderAction,
  reduxSetLoadInstrumentPricingReady as reduxSetLoadInstrumentPricingReadyAction,
  reduxWSLatency as reduxWSLatencyAction,
  reduxSetCurrentDate as reduxSetCurrentDateAction,
  reduxOrderUpdate as reduxOrderUpdateAction,
  reduxSetSystemStatus as reduxSetSystemStatusAction,
  reduxTobUpdate as reduxTobUpdateAction,
  reduxCreditCheck as reduxCreditCheckAction,
  reduxWebsocketMonitor as reduxWebsocketMonitorAction,
  addRfqSubscription as addRfqSubscriptionAction,
  updateRfqSubscription as updateRfqSubscriptionAction,
} from '~actions/index';
import { audioSell, audioSellSpanish } from '~utils/sounds';
import {
  skipRateEngineOrder,
  filterRateEngineOrders,
} from '~utils/rateEngineClientOrders';
import { userDetailsSelector } from '~selectors/userDetailsSelector';
import orderSettingsSelector from '~selectors/orderSettingsSelector';
import { globalSettingsSelector } from '~selectors/settingsSelector';
import { allInstrumentsSelector } from '~selectors/instrumentsSelector';
import followClientsSelector from '~selectors/followClientsSelector';
import {
  ViewerNotification,
  viewerNotificationFields,
} from '~components/notifications/viewerNotification';
import {
  createUserNotification,
  userNotificationMessage,
  userNotificationType,
  validateSessionTime,
  validateToday,
  StpNotificationText,
  ViewerNotificationText,
} from '~components/notifications/userNotification';
import { fetchWidgetUserSettings } from '~components/layout/FlexLayout/utils';
import createTestOrderNotification from '~components/notifications/testOrderNotification';
import probabilityCheck from '~utils/probabilityCheck';
import appEnvCfg from '~configs/app';
import {
  getPartialOrders,
  getTodayOrders,
  getViewUsers,
} from '../callers/getOrdersCall';
import { useWebsocket } from '../hooks/useWebsocket';
import useMetalsFixing from '../hooks/useMetalsFixing';
import setActiveStatusUser from '../callers/activeUser';
import getUserInformation from '../callers/getUserInformation';
import getViewers from '../callers/getViewers';
import getEnabledWidgets from '../callers/getEnabledWidgets';
import getSettingByDomainAndKey from '../callers/getSettingByDomainAndKey';

const FlexLayoutWrapper = lazy(() => import('../components/layout/FlexLayout'));

const PopoutHelper = lazy(() => import('../components/layout/PopoutHelper'));

window.interactions = [];

const viewerNotifiedOrders: { [key: string]: boolean } = {};
const notifiedOrders: { [key: string]: Order | string | boolean } = {};

type DashboardProps = {
  handleAuth0WsFail?: () => void;
  popout: boolean;
  subscriptions: any;
  assets: Instrument[];
  globalSettings: {
    initialOrderNotification: boolean;
    lang: string;
    sound: boolean;
    soundViewer: boolean;
    notifyFixed: boolean;
    notifyFixedGlobal: boolean;
    notifyFixedViewer: boolean;
  };
  ordersRedux: { [key: string]: Order };
  stpRows: any;
  widgetsConfiguration: {
    orders_settings: {
      viewer_covers: boolean;
    };
    partial_widget: {
      enabled: boolean;
    };
    blotter_widget: {
      show_partials: boolean;
    };
  };
  userDetails: any;
  followClients: any;
  skipRateEngineClientOrders: boolean;
  userPermissions: () => void;
  reduxSetMatcherSystemStatus: () => void;
  reduxSetSessionStatus: () => void;
  reduxSetUserSettings: (settings: any, domain: string) => void;
  reduxSetPermissions: (data: string) => void;
  reduxFollowClients: (data: string) => void;
  reduxSetSessionServiceStartup: () => void;
  reduxBookUpdate: () => void;
  reduxUpdateAssetRfq: () => void;
  reduxRfqUpdate: () => void;
  reduxSetHistoricalOrders: () => void;
  reduxFollowOrder: () => void;
  reduxInstrumentsCreditCheck: () => void;
  reduxDisableUI: () => void;
  reduxPostAsset: (data: Instrument[]) => void;
  reduxIncomingOrder: (orders: Order[]) => void;
  reduxStpUpdate: (order: Order) => void;
  reduxUpdateAsset: (instrument: Instrument) => void;
  reduxCompletedOrder: (isCompleted: boolean) => void;
  reduxSetLoadInstrumentPricingReady: () => void;
  reduxWSLatency: (latency: number) => void;
  reduxSetCurrentDate: () => void;
  reduxOrderUpdate: (order: Order) => void;
  reduxSetSystemStatus: (status: {
    wsStatus: boolean;
    webSocketStatus?: boolean;
  }) => void;
  reduxTobUpdate: (tob: { i: number; m: string; b: string; a: string }) => void;
  reduxCreditCheck: (data: any) => void;
  reduxWebsocketMonitor: () => void;
  reduxAddRfqSubscription: () => void;
  reduxUpdateRfqSubscription: () => void;
};

const Dashboard = ({
  handleAuth0WsFail,
  popout = false,
  subscriptions = {} as { [key: string]: Subscription },
  assets = [],
  globalSettings = {
    initialOrderNotification: false,
    lang: 'es',
    sound: false,
    soundViewer: false,
    notifyFixed: false,
    notifyFixedGlobal: false,
    notifyFixedViewer: false,
  },
  ordersRedux = {},
  stpRows = [],
  widgetsConfiguration = {
    orders_settings: {
      viewer_covers: false,
    },
    partial_widget: {
      enabled: false,
    },
    blotter_widget: {
      show_partials: false,
    },
  },
  userDetails = {},
  followClients = {},
  skipRateEngineClientOrders,
  userPermissions,
  reduxSetMatcherSystemStatus,
  reduxSetSessionStatus,
  reduxSetUserSettings,
  reduxSetPermissions,
  reduxFollowClients,
  reduxSetSessionServiceStartup,
  reduxBookUpdate,
  reduxUpdateAssetRfq,
  reduxRfqUpdate,
  reduxSetHistoricalOrders,
  reduxFollowOrder,
  reduxInstrumentsCreditCheck,
  reduxDisableUI,
  reduxPostAsset,
  reduxIncomingOrder,
  reduxStpUpdate,
  reduxUpdateAsset,
  reduxCompletedOrder,
  reduxSetLoadInstrumentPricingReady,
  reduxWSLatency,
  reduxSetCurrentDate,
  reduxOrderUpdate,
  reduxSetSystemStatus,
  reduxTobUpdate,
  reduxCreditCheck,
  reduxWebsocketMonitor,
  reduxAddRfqSubscription,
  reduxUpdateRfqSubscription,
}: DashboardProps) => {
  const location = useLocation();
  const isPopoutRef = useRef(location.pathname.includes('widget'));
  const [initialDataReady, setInitialDataReady] = useState(false);
  const websocket = useWebsocket({
    reduxWebsocketMonitor,
    ready: initialDataReady,
    handleAuth0WsFail,
  });

  const [glow, setGlow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [batchOrders, setBatchOrders] = useState([]);
  const [stpOrder, setStpOrder] = useState<Order>({} as Order);
  const subRef: any = useRef();
  const assetsRef = useRef<Instrument[]>();
  const globalSettingsRef = useRef<DashboardProps['globalSettings']>();
  const globalWidgetsConfigRef =
    useRef<DashboardProps['widgetsConfiguration']>();
  const globalStpRowsRef = useRef<Order[]>();
  const pingHeartbeat = useRef<ReturnType<typeof setTimeout>>();
  const username = useRef();
  const isViewer = useRef();
  const latency = useRef(Date.now());
  const skippedHearbeats = useRef(0);
  const popoutRef = useRef(popout);
  const ordersTimeout = useRef<ReturnType<typeof setTimeout>>();
  const batchOrdersTimeout = useRef<ReturnType<typeof setTimeout>>();
  const completedOrderTimeout = useRef<ReturnType<typeof setTimeout>>();
  const dispatch = useDispatch();
  useMetalsFixing();

  const fetchLayout = async () => {
    const data = await getSettingByDomainAndKey('flex_layout', window.APP_NAME);
    if (data) {
      reduxSetUserSettings(data.flex_layout, 'flex_layout');

      getInitialLayout(data);
    }
  };

  const fetchGlobalSettings = async () => {
    await fetchWidgetUserSettings('global_settings', '', dispatch);
  };

  const fetchSlippageGlobal = async () => {
    await fetchWidgetUserSettings('slippage_widget', '', dispatch);
  };

  const fetchEnabledWidgets = async () => {
    const data = await getEnabledWidgets();

    if (data) {
      reduxSetPermissions(data);
    }
  };

  const fetchUserInformation = async () => {
    const data = await getUserInformation();

    if (data) {
      reduxSetUserSettings(data, 'global_settings');
    }
  };

  const fetchViewers = async () => {
    const data = await getViewers();

    if (data) {
      reduxFollowClients(data);
    }
  };

  /**
   * Monitor if connection is alive with Web Server
   *
   * 5 second interval
   *
   * 504 = UI <--> Web Server (transport: socket.io) 20 missed = 100 secs no heartbeat
   */
  const startPing = () => {
    reduxSetSystemStatus({ wsStatus: true, webSocketStatus: true });
    if (pingHeartbeat.current) clearInterval(pingHeartbeat.current);
    skippedHearbeats.current = 0;
    latency.current = Date.now();

    pingHeartbeat.current = setInterval(() => {
      if (!latency.current) {
        latency.current = Date.now();
      }
      skippedHearbeats.current += 1;

      if (skippedHearbeats.current > 20) {
        reduxSetSystemStatus({ wsStatus: false });
      }

      latency.current = Date.now();

      websocket.emit('msg', {
        msgType: 'ping',
        data: { platform: window.APP_NAME },
      });
    }, 5000);
  };

  useEffect(
    () => () => {
      // remove all websocket listeners on component unmount
      websocket.removeAllListeners();
    },
    []
  );

  useEffect(() => {
    // start new session
    sessionStorage.removeItem('fetchPartialOrders');
    sessionStorage.removeItem('fetchYesterdayStartAt');
    sessionStorage.removeItem('clickTabInBlotter');

    const fetchInitialData = async () => {
      await Promise.all([
        fetchLayout(),
        fetchEnabledWidgets(),
        fetchUserInformation(),
        fetchViewers(),
        fetchGlobalSettings(),
        fetchSlippageGlobal(),
      ]);

      setInitialDataReady(true);
    };

    if (isPopoutRef.current) {
      setInitialDataReady(true);
    } else {
      fetchInitialData();
    }

    reduxSetCurrentDate();

    // clean disable_ui msg in the localStorage
    // Fe1pGpjGnN6g: {"source":true,"type":"disable_user_interface","payload":{"disable_ui":true}}
    window.addEventListener('beforeunload', () => {
      localStorage?.removeItem(window.identifier);
    });
  }, []);

  useEffect(() => {
    if (initialDataReady) {
      websocket.on('load-subscription', loadSubscription);
      websocket.on(
        'load-instrument-pricing-ready',
        loadCurrentPricingSubscription
      );
      websocket.on('set-permission', userPermissions);
      websocket.on('matcher-system-status', reduxSetMatcherSystemStatus);
      websocket.on('session-status', reduxSetSessionStatus);
      websocket.on('session-service-startup', reduxSetSessionServiceStartup);
      websocket.on('book-update', reduxBookUpdate);
      websocket.on('update-subscription', reduxUpdateAssetRfq);
      websocket.on('rfq-update', reduxRfqUpdate);
      websocket.on('rfq-order-new', orderUpdate);
      websocket.on('order-update', orderUpdate);
      websocket.on('test-order', testOrder);
      websocket.on('order-update-batch', orderBatchUpdate);
      websocket.on('historical-orders-dates', reduxSetHistoricalOrders);
      websocket.on('logout', logout);
      websocket.on('tob-update', tobUpdate);
      websocket.on('follow-order', reduxFollowOrder);
      websocket.on('setting', settingUpdate);
      websocket.on('credit-check-update', creditCheck);
      websocket.on('rfq-subscribe', reduxAddRfqSubscription);
      websocket.on('rfq-subscribe-update', reduxUpdateRfqSubscription);
      websocket.on('rfq-unsubscribe', reduxUpdateRfqSubscription);
      websocket.on('instruments-credit-check', reduxInstrumentsCreditCheck);
      websocket.on('stp-update', stpUpdate);
      websocket.on('reload-ui', reloadUI);
      websocket.on('disable-ui', reduxDisableUI);
      websocket.on('pong', pongCheck);
      websocket.on('auth-result', socketConnected);
    }
  }, [initialDataReady]);

  useEffect(() => {
    subRef.current = subscriptions;
  }, [subscriptions]);

  useEffect(() => {
    assetsRef.current = assets;
  }, [assets]);

  useEffect(() => {
    popoutRef.current = popout;
  }, [popout]);

  useEffect(() => {
    globalWidgetsConfigRef.current = widgetsConfiguration;
  }, [widgetsConfiguration]);

  useEffect(() => {
    globalStpRowsRef.current = stpRows;
  }, [stpRows]);

  useEffect(() => {
    username.current = userDetails.username;
  }, [userDetails.username]);

  useEffect(() => {
    globalSettingsRef.current = globalSettings;
  }, [globalSettings]);

  useEffect(() => {
    if (ordersTimeout.current) {
      clearTimeout(ordersTimeout.current);
    }
    if (!isPopoutRef.current) {
      if (orders.length) {
        ordersTimeout.current = setTimeout(() => {
          const realOrders = [...orders];
          const ol = realOrders.length;
          reduxIncomingOrder(realOrders);
          setOrders((c) => c.slice(ol));
        }, 250);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (batchOrdersTimeout.current) {
      clearTimeout(batchOrdersTimeout.current);
    }
    if (!isPopoutRef.current) {
      if (batchOrders.length) {
        batchOrdersTimeout.current = setTimeout(() => {
          reduxIncomingOrder([...batchOrders]);
        }, 250);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchOrders]);

  useEffect(() => {
    globalSettingsRef.current = globalSettings;
  }, [globalSettings]);

  useEffect(() => {
    const {
      organizations = [],
      users = [],
      super_orders_viewer: superOrdersViewer = false,
      super_shadow_viewer: superShadowViewer = false,
    } = followClients || {};
    const viewerOrg = organizations?.length || 0;
    const viewerUsu = users?.length || 0;

    isViewer.current =
      viewerOrg > 0 || viewerUsu > 0 || superOrdersViewer || superShadowViewer;
  }, [followClients]);

  useEffect(() => {
    const currGlobalSettings = globalSettingsRef.current;
    if (Object.keys(stpOrder).length && !popoutRef.current) {
      const dataParent = ordersRedux[stpOrder.client_order_id];

      if (dataParent) {
        const stateOrderStop = 'FILLED';

        if (dataParent.username !== username.current) {
          if (currGlobalSettings.notifyFixedViewer) {
            const title = dataParent.organization_name;
            const hide = !currGlobalSettings.notifyFixed;
            const text = StpNotificationText({
              dataParent,
              order: stpOrder,
              stateOrderStop,
              lang: currGlobalSettings.lang,
              orderLang,
              isCurrentUser: false,
            });

            const fields = viewerNotificationFields({ title, text, hide });
            const opts = {
              soundViewer: currGlobalSettings.soundViewer,
            };
            ViewerNotification(fields, opts);
          }
        } else {
          const text = StpNotificationText({
            dataParent,
            order: stpOrder,
            stateOrderStop,
            lang: currGlobalSettings.lang,
            orderLang,
            isCurrentUser: true,
          });

          createNotification(text, stateOrderStop);
        }

        if (currGlobalSettings.sound) {
          if (currGlobalSettings.lang === 'es') {
            audioSellSpanish.play().catch((err) => {
              console.warn(err);
            });
          } else {
            audioSell.play().catch((err) => {
              console.warn(err);
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stpOrder]);

  const stpUpdate = useCallback(
    (data) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty('row_bd')) setStpOrder(data);
      reduxStpUpdate(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const socketConnected = () => {
    const token = `${sessionStorage.getItem('token')}`;

    // reset after connection
    reduxSetSystemStatus({ wsStatus: true, webSocketStatus: true }); // set do default values all
    if (pingHeartbeat.current) clearInterval(pingHeartbeat.current);
    skippedHearbeats.current = 0;
    latency.current = Date.now();

    if (!isPopoutRef.current) {
      getTodayOrders({
        ...userDetails,
        token,
      }).then((todayOrders) => {
        orderBatchUpdate(todayOrders);
      });

      getViewUsers({
        ...userDetails,
        viewers: followClients,
        token,
      }).then((viewerOrders) => {
        orderBatchUpdate(viewerOrders);
      });

      // only run if show_partials
      if (widgetsConfiguration?.blotter_widget?.show_partials) {
        getPartialOrders({
          ...userDetails,
          token,
          viewers: followClients,
        }).then((data) => {
          if (Array.isArray(data)) {
            data.forEach((stp) => stpUpdate(stp));
          }
        });
      }
    }

    startPing();

    websocket.emit('msg', {
      msgType: 'load-initial-instruments',
      data: {
        token: `${sessionStorage.getItem('token')}`,
      },
    });
  };

  const pongCheck = useCallback(async () => {
    skippedHearbeats.current = 0;
    const lt = Date.now() - latency.current;
    latency.current = 0;
    reduxSetSystemStatus({ wsStatus: true });
    reduxWSLatency(lt);

    if (probabilityCheck(0.85)) {
      await setActiveStatusUser({
        ping: lt,
        platform: window.APP_NAME,
        token: sessionStorage.getItem('token'),
      });
    }

    // set current date in reducer to update resetTime in pnl
    reduxSetCurrentDate();

    if (
      lt > appEnvCfg().WEBSOCKET_LATENCY_THRESHOLD_MAJOR &&
      document?.visibilityState === 'visible'
    ) {
      logger.logWarning('latency websocket threshold breached', {
        isLogIfHidden: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSubscription = (data: Instrument[]) => {
    const newAssets = assetsRef.current;
    const newSubscriptions = subRef.current;

    if (
      !newAssets.length ||
      newAssets.findIndex((a) => a.instrument_id === data[0].instrument_id) ===
        -1
    )
      reduxPostAsset(data);
    else if (data.length === 1) reduxUpdateAsset(data[0]);

    Object.keys(newSubscriptions).map((id) =>
      Object.keys(newSubscriptions[id]).map((algo) =>
        websocket.emit('msg', {
          msgType: 'start-instrument-subscription',
          data: { id: Number(id), algo },
        })
      )
    );
  };

  const loadCurrentPricingSubscription = () => {
    reduxSetLoadInstrumentPricingReady();
    const newSubscriptions = subRef.current;

    // instrumentAlgoWidgetSubs(newSubscriptions).forEach((sub) => {
    //   console.log(`msg -> upstream START SUB id: ${sub[0]} algo: ${sub[1]}`);
    //   logWs.trace(`msg -> upstream START SUB id: ${sub[0]} algo: ${sub[1]}`);
    //   websocket.emit('msg', {
    //     msgType: 'start-instrument-subscription',
    //     data: { id: Number(sub[0]), algo: sub[1] },
    //   });
    // });

    Object.keys(newSubscriptions).map((id) =>
      Object.keys(newSubscriptions[id]).map((algo) =>
        websocket.emit('msg', {
          msgType: 'start-instrument-subscription',
          data: { id: Number(id), algo },
        })
      )
    );
  };

  /**
   * Creates a user notification of an order just when needed
   * @param {*} data
   * @returns
   */
  const checkForUserNotification = useCallback((data) => {
    const currGlobalSettings = globalSettingsRef.current;
    const { partial_widget: partialWidget } = globalWidgetsConfigRef.current;
    const globalStpRows = globalStpRowsRef.current;

    /** Conditions to not create notifications */
    if (
      partialWidget &&
      partialWidget.enabled &&
      // eslint-disable-next-line no-prototype-builtins
      !data.hasOwnProperty('row_bd') &&
      globalStpRows[data.client_order_id]
    ) {
      return;
    }

    // Skip notifications ******************************/
    if (popoutRef.current) return;
    if (data.replay && !data.re_client_org_name) return;
    if (!validateToday(Number(data.trade_date))) return;
    if (!validateSessionTime(data.timestamp)) return;
    /** ********************************************** */

    // eslint-disable-next-line spaced-comment
    /******************************************/

    const status = returnStatusUtil(data);
    const msg = userNotificationMessage(
      data,
      status,
      orderLang,
      currGlobalSettings.lang
    );

    const notificationType = userNotificationType(
      status,
      Number(data.qty_filled)
    );
    const sound =
      (notificationType === 'partial' || notificationType === 'complete') &&
      currGlobalSettings.sound;

    const opts = {
      showNotification: currGlobalSettings.notifyFixedGlobal,
      sound,
      side: data.side,
      lang: currGlobalSettings.lang,
    };

    if (
      notificationType !== 'cancel' &&
      window.APP_NAME.includes('edgefx') &&
      !notifiedOrders[data.client_order_id]
    ) {
      setGlow(true);
    }

    // New Orders
    if (status === 'NEW') {
      opts.showNotification = currGlobalSettings.initialOrderNotification;
      createUserNotification(msg, 'active', opts);
      return;
    }

    if (
      data.re_client_org_name &&
      notifiedOrders[data.client_order_id] === undefined
    ) {
      notifiedOrders[data.client_order_id] = 'pending';
      return;
    }

    // Finished orders
    if (
      (Number(data.qty_open) === 0 && !notifiedOrders[data.client_order_id]) ||
      notifiedOrders[data.client_order_id] === 'pending'
    ) {
      notifiedOrders[data.client_order_id] = true;
      createUserNotification(msg, notificationType, opts);
      return;
    }

    // Partial Orders
    if (notificationType === 'partial') {
      createUserNotification(msg, notificationType, opts);
    }
  }, []);

  /**
   * Creates a viewer notification of an order when
   * the user has viewer permissions only
   * @param {*} data
   */
  const checkForViewerNotification = useCallback((data) => {
    const {
      client_order_id: clientOrderId,
      organization_name: organizationName,
      trade_date: tradeDate,
      status,
    } = data;
    const currGlobalSettings = globalSettingsRef.current;
    const identifier = `${clientOrderId}`;
    const orderExist = !!viewerNotifiedOrders[identifier];

    if (
      status === 'FILLED' &&
      validateToday(Number(tradeDate)) &&
      validateSessionTime(data.timestamp) &&
      !orderExist
    ) {
      const opts = {
        soundViewer: currGlobalSettings.soundViewer,
        showNotification: currGlobalSettings.notifyFixedViewer,
      };
      const title = organizationName;
      const text = ViewerNotificationText({
        lang: currGlobalSettings.lang,
        data,
        orderLang,
      });
      const hide = !currGlobalSettings.notifyFixed;

      const fields = viewerNotificationFields({ title, text, hide });
      ViewerNotification(fields, opts);
      viewerNotifiedOrders[identifier] = true;
    }
  }, []);

  const checkOrderCompleted = useCallback((data) => {
    if (!data.replay && popoutRef.current) {
      const status = returnStatusUtil(data);
      if (
        status === 'PARTIALLY_FILLED' ||
        (status === 'CANCELED' && Number(data.qty_filled) > 0) ||
        status === 'FILLED'
      ) {
        clearTimeout(completedOrderTimeout.current);
        reduxCompletedOrder(true);
        completedOrderTimeout.current = setTimeout(() => {
          reduxCompletedOrder(false);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const testOrder = useCallback(({ accepted, ...data }) => {
    createTestOrderNotification(data, accepted ? 'accepted' : 'rejected');
  }, []);

  const orderUpdate = useCallback(
    (data) => {
      if (data.order_type === 'PEGG' || data.order_type === 'PEGGTAKETOP') {
        reduxOrderUpdate(data);
      }

      if (skipRateEngineOrder(data, skipRateEngineClientOrders)) return;

      setOrders((old) => [...old, data]);
      checkOrderCompleted(data);

      if (
        isViewer.current &&
        data.username !== username.current &&
        !data.cover_organization_name &&
        !data.re_client_org_name
      ) {
        checkForViewerNotification(data);
      } else {
        checkForUserNotification(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkOrderCompleted,
      checkForViewerNotification,
      checkForUserNotification,
      skipRateEngineClientOrders,
    ]
  );

  const orderBatchUpdate = useCallback(
    (batch) => {
      if (Array.isArray(batch)) {
        const notNullOrders = filterRateEngineOrders(
          batch,
          skipRateEngineClientOrders
        );

        setBatchOrders((old) => [...old, ...notNullOrders]);
      }
    },
    [skipRateEngineClientOrders]
  );

  const createNotification = useCallback((data, type) => {
    const currGlobalSettings = globalSettingsRef.current;
    if (!currGlobalSettings.notifyFixedGlobal) return null;

    switch (type) {
      case 'cancel':
        return NotificationManager.error(data, null, 10000);
      case 'partial':
        return NotificationManager.warning(data, null, 10000);
      case 'active':
        return NotificationManager.info(data, null, 10000);
      case 'no_notification': // this should be the default.
        return null;
      default:
        return NotificationManager.success(data, null, 10000);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('logout');
    window.location.reload();
  }, []);

  const reloadUI = useCallback(() => {
    window.location.reload();
  }, []);

  const tobUpdate = useCallback((data) => {
    reduxTobUpdate({
      i: data.TOB[0], // instrument_id
      m: data.TOB[1], // volume in millions
      b: data.TOB[2], // bid
      a: data.TOB[3], // ask
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingUpdate = useCallback((data) => {
    const domains = Object.keys(data);
    domains.forEach((domain) => {
      if (domain && domain !== 'instruments_widget')
        // Ignore the instruments_widget setting update, since it is handled by the drag n drop event
        reduxSetUserSettings(data[domain], domain);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creditCheck = useCallback((data) => {
    reduxCreditCheck(data?.data || data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialDataReady || !assets.length) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      {!isPopoutRef.current ? (
        <FlexLayoutWrapper
          setGlowing={setGlow}
          glow={glow}
          socket={websocket}
          orderBatchUpdate={orderBatchUpdate}
          stpUpdate={stpUpdate}
        />
      ) : (
        <div id="modal-root">
          <PopoutHelper
            // @ts-expect-error disable this for now
            socket={websocket}
            // @ts-expect-error disable this for now
            orderBatchUpdate={orderBatchUpdate}
            // @ts-expect-error disable this for now
            stpUpdate={stpUpdate}
          />
        </div>
      )}
    </Suspense>
  );
};

function mapStateToProps(state: RootState) {
  return {
    globalSettings: globalSettingsSelector(state),
    assets: allInstrumentsSelector(state),
    subscriptions: state.subscriptions,
    popout: state.popout,
    ordersRedux: state.orders.followClient
      ? state.orders.followOrders
      : state.orders.orders,
    widgetsConfiguration: state.widgets_configuration,
    stpRows: state.stp,
    followClients: followClientsSelector(state.followClients),
    userDetails: userDetailsSelector(state),
    skipRateEngineClientOrders:
      orderSettingsSelector(state).skipRateEngineClientOrders,
  };
}

export default connect(mapStateToProps, {
  userPermissions: userPermissionsAction,
  reduxSetMatcherSystemStatus: reduxSetMatcherSystemStatusAction,
  reduxSetSessionStatus: reduxSetSessionStatusAction,
  reduxSetUserSettings: reduxSetUserSettingsAction,
  reduxSetPermissions: reduxSetPermissionsAction,
  reduxFollowClients: reduxFollowClientsAction,
  reduxSetSessionServiceStartup: reduxSetSessionServiceStartupAction,
  reduxBookUpdate: reduxBookUpdateAction,
  reduxUpdateAssetRfq: reduxUpdateAssetRfqAction,
  reduxRfqUpdate: reduxRfqUpdateAction,
  reduxSetHistoricalOrders: reduxSetHistoricalOrdersAction,
  reduxFollowOrder: reduxFollowOrderAction,
  reduxInstrumentsCreditCheck: reduxInstrumentsCreditCheckAction,
  reduxDisableUI: reduxDisableUIAction,
  reduxPostAsset: reduxPostAssetAction,
  reduxIncomingOrder: reduxIncomingOrderAction,
  reduxStpUpdate: reduxStpUpdateAction,
  reduxUpdateAsset: reduxUpdateAssetAction,
  reduxCompletedOrder: reduxCompletedOrderAction,
  reduxSetLoadInstrumentPricingReady: reduxSetLoadInstrumentPricingReadyAction,
  reduxWSLatency: reduxWSLatencyAction,
  reduxSetCurrentDate: reduxSetCurrentDateAction,
  reduxOrderUpdate: reduxOrderUpdateAction,
  reduxSetSystemStatus: reduxSetSystemStatusAction,
  reduxTobUpdate: reduxTobUpdateAction,
  reduxCreditCheck: reduxCreditCheckAction,
  reduxWebsocketMonitor: reduxWebsocketMonitorAction,
  reduxAddRfqSubscription: addRfqSubscriptionAction,
  reduxUpdateRfqSubscription: updateRfqSubscriptionAction,
})(React.memo(Dashboard));
