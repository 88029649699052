import { NotificationManager } from '~components/common/Notifications';
import { formatNumberToCommas } from '~utils/formatNumber';

export const testOrderNotification = (
  { symbol, qty }: { symbol: string; qty: string },
  type: 'accepted' | 'rejected'
) => {
  const message = `${symbol} - ${formatNumberToCommas(qty)} ${type}`;

  switch (type) {
    case 'accepted':
      return NotificationManager.success(message, null, 10000);
    case 'rejected':
      return NotificationManager.error(message, null, 10000);
    default:
      return null;
  }
};

export default testOrderNotification;
