import { Order } from '~types/common';

export const skipRateEngineOrder = (
  order: Order,
  skipRateEngineClientOrders: boolean
) => {
  const isReOrder = !!(order.re_value1 || order.re_value2);
  const isNotCover = !order.cover_organization_name;
  return skipRateEngineClientOrders && isReOrder && isNotCover;
};

export const filterRateEngineOrders = (
  orders: Order[],
  skipRateEngineClientOrders: boolean
) =>
  orders.filter(
    (o) => o !== null && !skipRateEngineOrder(o, skipRateEngineClientOrders)
  );
